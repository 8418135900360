import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material';
import styles from './Filter.module.scss';
import { ApiCallState } from '../../api';
import React from 'react';

export interface StringAutofillProps {
  options?: ApiCallState<string[]>;
  multiple?: boolean;
}

export default function FilterStringAutoFill(props: StringAutofillProps) {
  const { options, multiple } = props;
  const [loading, setLoading] = React.useState(false);

  return (
    <Autocomplete
      className={`${styles.centered} ${styles.flex_container_width}`}
      onOpen={() => setLoading(true)}
      onClose={() => setLoading(false)}
      renderInput={(params) => (
        <TextField
          {...params}
          size='small'
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      multiple={multiple ?? true}
      loading={loading}
      options={options?.result ?? []}
    />
  );
}
