import { Box, InputAdornment, TextField, Tabs, Tab } from '@mui/material';
import { useState } from 'react';
import styles from './Filter.module.scss';

export interface NumberProps {
  min?: number;
  max?: number;
  unitName?: string;
  mode?: 'exact' | 'range' | 'both';
  defaultSelected?: 'equals' | 'range';
}

interface ExactNumberInputProps {
  value: number | undefined;
  onChange: (value: number | undefined) => void;
  unitName?: string;
}

interface RangeNumberInputProps {
  minValue: number | undefined;
  maxValue: number | undefined;
  onMinChange: (value: number | undefined) => void;
  onMaxChange: (value: number | undefined) => void;
  unitName?: string;
}

const ExactNumberInput: React.FC<ExactNumberInputProps> = ({ value, onChange, unitName }) => (
  <TextField
    value={value}
    onChange={(e) => onChange(Number(e.target.value))}
    InputProps={{
      endAdornment: unitName ? <InputAdornment position='start'>{unitName}</InputAdornment> : null,
    }}
    fullWidth
    size='small'
  />
);

const RangeNumberInput: React.FC<RangeNumberInputProps> = ({
  minValue,
  maxValue,
  onMinChange,
  onMaxChange,
  unitName,
}) => (
  <Box display='flex' gap={2}>
    <TextField
      value={minValue}
      onChange={(e) => onMinChange(Number(e.target.value))}
      InputProps={{
        endAdornment: unitName ? <InputAdornment position='start'>{unitName}</InputAdornment> : null,
      }}
      fullWidth
      size='small'
      label='start'
    />
    <TextField
      value={maxValue}
      onChange={(e) => onMaxChange(Number(e.target.value))}
      InputProps={{
        endAdornment: unitName ? <InputAdornment position='start'>{unitName}</InputAdornment> : null,
      }}
      fullWidth
      size='small'
      label='end'
    />
  </Box>
);

export default function FilterNumber(props: NumberProps) {
  const { unitName, mode = 'both', defaultSelected = 'equals' } = props;
  const [selectedTab, setSelectedTab] = useState(defaultSelected === 'range' ? 1 : 0);
  const [exactValue, setExactValue] = useState<number | undefined>();
  const [minValue, setMinValue] = useState<number | undefined>();
  const [maxValue, setMaxValue] = useState<number | undefined>();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      className={`${styles.flex_container} ${styles.flex_vertical} ${styles.flex_container_width} ${styles.centered}`}
    >
      {mode === 'both' ? (
        <>
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label='number filter tabs'>
            <Tab label='Genau' />
            <Tab label='Bereich' />
          </Tabs>
          {selectedTab === 0 && <ExactNumberInput value={exactValue} onChange={setExactValue} unitName={unitName} />}
          {selectedTab === 1 && (
            <RangeNumberInput
              minValue={minValue}
              maxValue={maxValue}
              onMinChange={setMinValue}
              onMaxChange={setMaxValue}
              unitName={unitName}
            />
          )}
        </>
      ) : mode === 'exact' ? (
        <ExactNumberInput value={exactValue} onChange={setExactValue} unitName={unitName} />
      ) : (
        <RangeNumberInput
          minValue={minValue}
          maxValue={maxValue}
          onMinChange={setMinValue}
          onMaxChange={setMaxValue}
          unitName={unitName}
        />
      )}
    </Box>
  );
}
