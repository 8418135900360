import { useState } from 'react';
import { Table } from '../components/Documentation/Table';
import { ResponsiveDrawer } from '../components/ResponsiveDrawer';
import { Box, Drawer, Grid } from '@mui/material';
import { DetailView } from '../components/DetailView/DetailView';
import { RESPONSIVE_DRAWER_HEIGHT, RESPONSIVE_DRAWER_WIDTH } from '../Constants';
import { ApiCallState } from '../api';
import { DeliveryDTO } from '../api/generated';
import FilterPanel from '../components/Documentation/FilterControlPanel';
import FilterHeader from '../components/Documentation/FilterHeader';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';

export function Documentation() {
  const [delivery, setDelivery] = useState<ApiCallState<DeliveryDTO>>();
  const [isOverlayShown, setOverlayShown] = useState(false);
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [currentlySelectedRowIndex, setCurrentlySelectedRowIndex] = useState<number>();
  const [numberOfRowsCurrentlyShown, setNumberOfRowsCurrentlyShown] = useState<number>();
  const [firstElementInTableSelected, setFirstElementInTableSelected] = useState<boolean>(false);
  const [lastElementInTableSelected, setLastElementInTableSelected] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<Array<{ name: string; value: string }>>([
    { name: 'Wiegeschein', value: '1234' },
    { name: 'Rechnung', value: '5678' },
    { name: 'Lieferschein', value: '9101' },
    { name: 'Bestellung', value: '1121' },
    { name: 'Angebot', value: '3141' },
    { name: 'Gutschrift', value: '5161' },
    { name: 'Auftrag', value: '7181' },
    { name: 'Kundennummer', value: '9202' },
    { name: 'Artikelnummer', value: '1222' },
    { name: 'Lieferant', value: '3242' },
  ]);

  const padding: number = 1; // The unit is rem

  enum RequestedDelivery {
    NEXT,
    PREVIOUS,
  }

  const checkForFirstAndLastElementSelected = (indexOfRequestedRow: number): void => {
    setFirstElementInTableSelected(indexOfRequestedRow === 0);
    setLastElementInTableSelected(indexOfRequestedRow === numberOfRowsCurrentlyShown!! - 1);
  };

  const handleClickOnDataGridRow = (value: DeliveryDTO, newlySelectedRowIndex: number) => {
    setDelivery({ result: value });
    setCurrentlySelectedRowIndex(newlySelectedRowIndex);
    if (!isOverlayShown) setOverlayShown(true);
    checkForFirstAndLastElementSelected(newlySelectedRowIndex);
  };

  function handleDifferentDeliveryRequested(requestedDelivery: RequestedDelivery) {
    const indexOfRequestedRow =
      requestedDelivery === RequestedDelivery.NEXT ? currentlySelectedRowIndex!! + 1 : currentlySelectedRowIndex!! - 1;
    const isRequestedRowIndexInRange = indexOfRequestedRow >= 0 && indexOfRequestedRow < numberOfRowsCurrentlyShown!!;
    if (!isRequestedRowIndexInRange) return;
    setCurrentlySelectedRowIndex(indexOfRequestedRow);
    checkForFirstAndLastElementSelected(indexOfRequestedRow);
  }

  const handleResetFilters = () => {
    setAppliedFilters([]);
    // Add logic to reset filters in FilterPanel
  };

  const handleRemoveFilter = (filterToRemove: { name: string; value: string }) => {
    setAppliedFilters(
      appliedFilters.filter((filter) => filter.name !== filterToRemove.name || filter.value !== filterToRemove.value)
    );
    // Add logic to remove filter in FilterPanel
  };

  const toggleFilterDrawer = () => {
    setFilterDrawerOpen(!isFilterDrawerOpen);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='de'>
      <Box display={'flex'}>
        <ResponsiveDrawer />
        <Box
          sx={{
            height: `calc(100% - ${RESPONSIVE_DRAWER_HEIGHT}px - 2*${padding}rem)`,
            width: `calc(100% - 2*${padding}rem)`,
            top: RESPONSIVE_DRAWER_HEIGHT,
            position: 'absolute',
            padding: `${padding}rem`,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'scroll',
          }}
        >
          <Grid container spacing={2} sx={{ height: '100%' }}>
            <Grid item xs={12} md={3} lg={2} sx={{ display: { xs: 'none', md: 'block', ml: 2 } }}>
              <FilterPanel onCloseDrawer={() => toggleFilterDrawer} />
            </Grid>
            <Grid item xs={12} md={9} lg={10} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Box sx={{ display: 'flex' }}>
                <FilterHeader
                  filters={appliedFilters}
                  onResetFilters={handleResetFilters}
                  onRemoveFilter={handleRemoveFilter}
                  onShowFilter={toggleFilterDrawer}
                />
              </Box>
              <Box sx={{ flex: 1, overflow: 'auto' }}>
                <Table
                  onRowSelect={handleClickOnDataGridRow}
                  requestedRowIndex={currentlySelectedRowIndex!!}
                  setNumberOfShownRows={setNumberOfRowsCurrentlyShown}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Drawer
          open={isOverlayShown}
          onClose={() => setOverlayShown(false)}
          anchor='right'
          PaperProps={{
            sx: {
              height: `calc(100vh - ${RESPONSIVE_DRAWER_HEIGHT}px)`,
              width: { xs: '100%', lg: `calc(80% - ${RESPONSIVE_DRAWER_WIDTH}px)`, md: '80%' },
              top: RESPONSIVE_DRAWER_HEIGHT,
            },
          }}
        >
          <DetailView
            delivery={delivery!!}
            nextButtonEnabled={!lastElementInTableSelected}
            previousButtonEnabled={!firstElementInTableSelected}
            hideOverlay={() => setOverlayShown(false)}
            requestNextItem={() => handleDifferentDeliveryRequested(RequestedDelivery.NEXT)}
            requestPreviousItem={() => handleDifferentDeliveryRequested(RequestedDelivery.PREVIOUS)}
          />
        </Drawer>
        <Drawer
          anchor='left'
          open={isFilterDrawerOpen}
          onClose={toggleFilterDrawer}
          PaperProps={{
            sx: {
              width: {
                xs: '90%',
                sm: '60%',
              },
              top: {
                xs: RESPONSIVE_DRAWER_HEIGHT - 8,
                sm: RESPONSIVE_DRAWER_HEIGHT,
              },
              height: {
                xs: `calc(100% - ${RESPONSIVE_DRAWER_HEIGHT}px + 8px)`,
                sm: `calc(100% - ${RESPONSIVE_DRAWER_HEIGHT}px)`,
              },
            },
          }}
        >
          <FilterPanel onCloseDrawer={toggleFilterDrawer} />
        </Drawer>
      </Box>
    </LocalizationProvider>
  );
}
