import React from 'react';
import FilterNumber, { NumberProps } from '../Filter/Number';
import FilterStringAutoFill, { StringAutofillProps } from '../Filter/StringAutofill';
import FilterDateRange, { IDateRangeProps } from '../Filter/DateRange';
import FilterEnum, { IEnumFilterProps } from '../Filter/Enum';
import { Box } from '@mui/material';
import styles from '../Filter/Filter.module.scss';

export enum FilterType {
  NumberRange = 'NumberRange',
  DateRange = 'DateRange',
  StringAutofill = 'StringAutofill',
  Enum = 'Enum',
}

interface IBaseFilterProps {
  name: string;
}

interface INumberType extends IBaseFilterProps, NumberProps {
  type: FilterType.NumberRange;
}

interface IDateRangeType extends IBaseFilterProps, IDateRangeProps {
  type: FilterType.DateRange;
}

interface IStringAutofillType extends IBaseFilterProps, StringAutofillProps {
  type: FilterType.StringAutofill;
}

interface IEnumOneOfType extends IBaseFilterProps, IEnumFilterProps {
  type: FilterType.Enum;
}

export type FilterProps = INumberType | IDateRangeType | IStringAutofillType | IEnumOneOfType;

const withAddButton = (Component: React.ComponentType<any>) => (props: any) =>
  (
    <Box
      className={`${styles.flex_container} ${styles.flex_vertical} ${styles.flex_container_width} ${styles.centered}`}
    >
      <Component {...props} />
      {/* <Button sx={{ margin: 'auto' }} variant='outlined'>
        Hinzufügen
      </Button> */}
    </Box>
  );

export default function FilterListItem(props: FilterProps): JSX.Element {
  switch (props.type) {
    case FilterType.NumberRange:
      return withAddButton(FilterNumber)(props);
    case FilterType.DateRange:
      return withAddButton(FilterDateRange)(props);
    case FilterType.StringAutofill:
      return withAddButton(FilterStringAutoFill)(props);
    case FilterType.Enum:
      return withAddButton(FilterEnum)({
        ...props,
        options: props.options,
        multiSelect: props.multiSelect,
        onChange: props.onChange,
      });
    default:
      return <div>Unsupported filter type</div>;
  }
}
