import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  Paper,
  Button,
  IconButton,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import TuneIcon from '@mui/icons-material/Tune';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import FilterListItem, { FilterProps, FilterType } from './FilterListItem';
import { RESPONSIVE_DRAWER_HEIGHT } from '../../Constants';
import styles from './FilterPanel.module.scss';

const filterItems: FilterProps[] = [
  { name: 'Wiegeschein', type: FilterType.StringAutofill },
  { name: 'Kunde', type: FilterType.StringAutofill },
  { name: 'SorteID', type: FilterType.Enum, multiSelect: false, options: { result: ['1', '2', '3'] } },
  {
    name: 'Sorte',
    type: FilterType.Enum,
    options: { result: ['Beton', 'Asphalt', 'Schotter', 'Erde', 'Kies', 'Sonstiges'] },
    multiSelect: true,
  },
  { name: 'AVV', type: FilterType.Enum, options: { result: ['170107', '170508', '190112'] }, multiSelect: true },
  { name: 'Datum', type: FilterType.DateRange },
  { name: 'Gewicht', type: FilterType.NumberRange, unitName: 't', mode: 'exact' },
  { name: 'Fahrzeug', type: FilterType.StringAutofill },
];

export interface FilterPanelProps {
  onCloseDrawer: () => void;
}

function FilterPanel(props: FilterPanelProps) {
  const [openFilters, setOpenFilters] = React.useState<Record<string, boolean>>({});
  const [itemCount, setItemCount] = React.useState<number>(0);

  const handleClick = (filter: string) => {
    setOpenFilters((prev) => ({
      ...prev,
      [filter]: !prev[filter],
    }));
  };

  const handleResetFilters = () => {
    // Reset logic here
    setItemCount(0);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        maxHeight: {
          lg: `calc(100vh - ${RESPONSIVE_DRAWER_HEIGHT}px - 3rem)`,
          md: `calc(100vh - ${RESPONSIVE_DRAWER_HEIGHT}px)`,
        },
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Box className={styles.filter_panel_header}>
        <Typography variant='h6' gutterBottom sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <TuneIcon sx={{ mr: 1 }} />
          Filter
        </Typography>
        <IconButton
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none' },
            ml: 'auto',
            mr: 2,
          }}
          onClick={() => props.onCloseDrawer()}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        <List>
          {filterItems.map((val, idx) => (
            <React.Fragment key={val.name}>
              {idx !== 0 && <Divider key={val.name} sx={{ width: '100%', margin: '0 auto' }} />}
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleClick(val.name)}>
                  {openFilters[val.name] ? <ExpandLess /> : <ExpandMore />}
                  <ListItemText primary={val.name} sx={{ paddingLeft: '8px' }} />
                </ListItemButton>
              </ListItem>
              <Collapse in={openFilters[val.name]} timeout='auto'>
                <Box sx={{ padding: 1, bgcolor: 'background.paper' }}>
                  <FilterListItem {...val} />
                </Box>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </Box>
      <Box
        sx={{
          display: { lg: 'none', md: 'none', xs: 'flex' },
          position: 'sticky',
          bottom: 0,
          width: '100%',
          bgcolor: 'background.paper',
          flexDirection: 'row',
          justifySelf: 'flex-end',
          justifyContent: 'space-evenly',
          gap: 2,
          alignItems: 'center',
          borderTop: '1px solid #e0e0e0',
        }}
      >
        <Button variant='outlined' color='primary' sx={{ flex: 1, m: 1 }} onClick={handleResetFilters}>
          Zurücksetzen
        </Button>
        <Typography sx={{ flex: 1, m: 1 }} variant='body1' align='center'>
          {itemCount} Einträge
        </Typography>
      </Box>
    </Paper>
  );
}

export default FilterPanel;
