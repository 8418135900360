import { DateTimeRangePicker } from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import styles from './Filter.module.scss';
import { renderDigitalClockTimeView } from '@mui/x-date-pickers-pro';
import { Box } from '@mui/material';

export interface IDateRangeProps {
  startDate?: Date;
  endDate?: Date;
}

export default function FilterDateRange(props: IDateRangeProps) {
  return (
    <DateTimeRangePicker
      className={`${styles.flex_container_width}`}
      views={['day', 'hours', 'minutes']}
      // currentMonthCalendarPosition={2}
      disableFuture
      ampm={false}
      displayWeekNumber
      // timeSteps={{ minutes: 10 }}
      viewRenderers={{ hours: renderDigitalClockTimeView }}
      sx={{
        '& .MuiFormControl-root': {
          margin: '0',
        },
      }}
      slotProps={{
        textField: { size: 'small', fullWidth: true },
        fieldRoot: {
          style: { flexDirection: 'column', gap: '1rem', margin: 'auto' },
        },
        fieldSeparator: { style: { display: 'none' } },
        desktopPaper: { sx: { marginTop: '4rem' } },
      }}
    />
  );
}
