import React from 'react';
import styles from './Filter.module.scss';
import { Box, FormControl, FormControlLabel, Radio, Checkbox, RadioGroup, List, ListItem } from '@mui/material';
import { ApiCallState } from '../../api';

export interface IEnumFilterProps {
  options: ApiCallState<string[]>;
  multiSelect?: boolean;
  onChange?: (selected: string[]) => void;
}

export const FilterEnum = (props: IEnumFilterProps) => {
  const { options, multiSelect } = props;

  const [selectedValues, setSelectedValues] = React.useState<string[] | ''>(multiSelect ? [] : '');

  return (
    <FormControl component='fieldset' className={`${styles.enum_list}`}>
      {multiSelect ? (
        <List>
          {options.result?.map((option) => (
            <ListItem key={option} disablePadding>
              <FormControlLabel
                control={<Checkbox checked={selectedValues.includes(option)} value={option} />}
                label={option}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <RadioGroup value={selectedValues}>
          <List>
            {options.result?.map((option) => (
              <ListItem key={option} disablePadding>
                <FormControlLabel value={option} control={<Radio />} label={option} />
              </ListItem>
            ))}
          </List>
        </RadioGroup>
      )}
    </FormControl>
  );
};

export default FilterEnum;
