/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {
  DataGridPro,
  deDE,
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
  GridColDef,
  GridFilterModel,
  GridRowId,
  GridSelectionModel,
  GridSortModel,
  gridVisibleRowCountSelector,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { ApiCallState, buildQueryParams, MUIOperatorMap } from '../../api';
import { Card, Tooltip } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { UserContext } from '../../UserContext';
import {
  DeliveryDTO,
  DeliveryFilterDTO,
  DeliveryQueryDTO,
  DeliveryService,
  DeliverySortDTO,
  PaginationDTO,
  QueryDTO,
} from '../../api/generated';

const string_allowed = ['contains', 'equals', 'notEquals'];
const number_allowed = ['=', '!=', '<=', '>'];
const datetime_allowed = ['is', 'onOrAfter', 'onOrBefore'];
const PAGE_SIZE: number = 100;

function filters(val: string, allowed: string[]) {
  return allowed.includes(val);
}

const columns: GridColDef[] = [
  {
    field: 'delivery_id',
    headerName: 'Wiegeschein',
    resizable: false,
    flex: 0.4,
    type: 'string',
    filterOperators: getGridStringOperators().filter((op) => {
      return filters(op.value, string_allowed);
    }),
  },
  {
    field: 'supplier',
    headerName: 'Kunde',
    resizable: false,
    flex: 0.7,
    type: 'string',
    filterOperators: getGridStringOperators().filter((op) => {
      return filters(op.value, string_allowed);
    }),
  },
  {
    field: 'material_id',
    headerName: 'Sorte ID',
    resizable: false,
    flex: 0.3,
    type: 'string',
    filterOperators: getGridStringOperators().filter((op) => {
      return filters(op.value, string_allowed);
    }),
  },
  {
    field: 'material',
    headerName: 'Sorte',
    resizable: false,
    flex: 0.7,
    type: 'string',
    filterOperators: getGridStringOperators().filter((op) => {
      return filters(op.value, string_allowed);
    }),
  },
  {
    field: 'avv',
    headerName: 'AVV',
    resizable: false,
    flex: 0.3,
    type: 'string',
    filterOperators: getGridStringOperators().filter((op) => {
      return filters(op.value, string_allowed);
    }),
  },
  {
    field: 'date',
    headerName: 'Datum',
    resizable: false,
    flex: 0.8,
    type: 'dateTime',
    filterOperators: getGridDateOperators().filter((op) => {
      return filters(op.value, datetime_allowed);
    }),
  },
  {
    field: 'image_status',
    headerName: 'Bild',
    resizable: false,
    flex: 0.3,
    type: 'boolean',
    filterable: true,
    renderCell: (params) => {
      if (params.value)
        return (
          <Tooltip title='Bild vorhanden' arrow>
            <CheckCircleIcon />
          </Tooltip>
        );
      else
        return (
          <Tooltip title='Warnung: Kein Bild vorhanden!' arrow>
            <ErrorOutlineIcon />
          </Tooltip>
        );
    },
  },
  {
    field: 'netto_weight',
    headerName: 'Gewicht (t)',
    resizable: false,
    flex: 0.5,
    type: 'number',
    filterOperators: getGridNumericOperators().filter((op) => {
      return filters(op.value, number_allowed);
    }),
  },
  {
    field: 'numberplate',
    headerName: 'Fahrzeug',
    resizable: false,
    flex: 0.5,
    type: 'string',
    filterOperators: getGridStringOperators().filter((op) => {
      return filters(op.value, string_allowed);
    }),
  },
  {
    field: 'construction_site',
    headerName: 'Baustelle',
    resizable: false,
    flex: 1,
    type: 'string',
    filterOperators: getGridStringOperators().filter((op) => {
      return filters(op.value, string_allowed);
    }),
  },
];

export interface BasicDataGridProps {
  onRowSelect: (value: DeliveryDTO, itemIndex: number) => void;
  setNumberOfShownRows: (numberOfShownRows: number) => void;
  requestedRowIndex: number;
}

enum QueryType {
  Sort,
  Filter,
  Paginate,
}

interface QueryAction {
  type: QueryType;
  values: PaginationDTO | DeliveryFilterDTO | DeliverySortDTO;
}

function query_reducer(state: DeliveryQueryDTO, action: QueryAction): DeliveryQueryDTO {
  switch (action.type) {
    case QueryType.Sort:
      return { ...state, sort: action.values as DeliverySortDTO };
    case QueryType.Filter: {
      const vals = JSON.parse(JSON.stringify(action)).values as DeliveryFilterDTO;
      if (vals.filterFields) {
        vals.filterFields = vals.filterFields.map((filter) => {
          // convert weight from tons to kg
          if (filter.filterField === 'netto_weight') {
            filter.value = `${parseFloat(filter.value as string) * 1000}`;
          }
          // convert date to end of day
          if (filter.filterField === 'date') {
            const date = new Date(filter.value as string);
            date.setHours(23, 59, 59, 999);
            filter.value = date.toISOString();
          }
          return filter;
        });
      }
      return { ...state, filter: vals };
    }
    case QueryType.Paginate: {
      return { ...state, paginate: action.values as PaginationDTO };
    }
    default: {
      throw new Error();
    }
  }
}

const defaultQueryState: DeliveryQueryDTO = {
  sort: { sortField: 'date', sortDirection: 'desc' },
  paginate: { page: 0, pageSize: PAGE_SIZE },
};

export function Table(props: BasicDataGridProps) {
  const [deliveries, setDeliveries] = React.useState<ApiCallState<DeliveryDTO[]>>({});
  const [totalItems, setTotalItems] = React.useState(0);
  const { user } = React.useContext(UserContext);
  const apiRef = useGridApiRef();

  type Reducer<S, A> = (prevState: S, action: A) => S;

  const [query, dispatchQuery] = React.useReducer<Reducer<DeliveryQueryDTO, QueryAction>>(
    query_reducer,
    defaultQueryState
  );

  React.useEffect(() => {
    apiRef.current.subscribeEvent('stateChange', () => {
      const count = gridVisibleRowCountSelector(apiRef.current.state);
      props.setNumberOfShownRows(count);
    });
  }, [apiRef]);

  function handleSortChange(model: GridSortModel): void {
    if (model.length === 0) return;
    const sort: DeliverySortDTO = {
      sortField: model[0].field as DeliverySortDTO['sortField'],
      sortDirection: model[0].sort as DeliverySortDTO['sortDirection'],
    };
    dispatchQuery({
      type: QueryType.Sort,
      values: sort,
    });
  }

  function handleFilterChange(model: GridFilterModel): void {
    const filter: DeliveryFilterDTO = {
      filterFields: model.items
        .filter((i) => !!i.columnField && !!i.value)
        .map((i) => ({
          filterField: i.columnField as any,
          operatorValue: MUIOperatorMap(i?.operatorValue),
          value: i.value,
        })),
      filterLinkOperator: model.linkOperator as DeliveryFilterDTO['filterLinkOperator'],
    };
    dispatchQuery({ type: QueryType.Filter, values: filter });
  }

  function notifyChange(newlySelectedRowID: GridRowId) {
    // This is the case if a row is still selected but is not shown anymore because a filter was applied that hides it
    if (!newlySelectedRowID) return;

    const delivery: DeliveryDTO = deliveries.result?.find((d) => d.id === newlySelectedRowID) as DeliveryDTO;

    const selectedDeliveryDataGridRowIndex: number =
      apiRef.current.getRowIndexRelativeToVisibleRows(newlySelectedRowID);

    props.onRowSelect(delivery, selectedDeliveryDataGridRowIndex);
  }

  React.useEffect(() => {
    (async () => {
      if (!user?.preferredJobsite?.id) return;
      setDeliveries({});
      return DeliveryService.deliveryControllerGetDeliveries({
        jobsiteId: user?.preferredJobsite.id || '',
        query: buildQueryParams(query as QueryDTO),
      });
    })()
      .then((result: any) => {
        if (result) {
          const { data, total } = result;
          const ds = data.map((d: DeliveryDTO) => {
            return {
              ...d,
              netto_weight: d.netto_weight / 1000,
              image_status: d.pictureIds.length !== 0,
            };
          });
          setDeliveries({ result: ds });
          setTotalItems(total);
        }
      })
      .catch((err) => {
        setDeliveries({ error: `${err.message}` });
      });
  }, [query, user]);

  React.useEffect(() => {
    if (!props.requestedRowIndex && props.requestedRowIndex !== 0) return; // Catch undefined and null but not 0
    const allRowIds: GridRowId[] = apiRef.current.getAllRowIds();
    const rowIdForNextRow: string = allRowIds[props.requestedRowIndex] as string;
    if (!rowIdForNextRow) return;
    apiRef.current.selectRow(rowIdForNextRow, true, true);
  }, [props.requestedRowIndex]);

  return (
    // NOTE: only use loading guard for initial loading; all subsequent updates are handled by the data grid
    <Card
      className='data-grid'
      elevation={8}
      sx={{
        borderRadius: '10px',
        display: 'flex',
        height: '100%',
        width: '100%',
      }}
    >
      <DataGridPro
        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
        apiRef={apiRef}
        columns={columns}
        rows={
          deliveries?.result?.map((d) => {
            return { ...d, date: new Date(d.date) };
          }) || []
        }
        disableMultipleSelection
        loading={!deliveries?.result}
        disableMultipleColumnsSorting
        disableColumnPinning
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        pagination
        sortingMode='server'
        filterMode='server'
        paginationMode='server'
        rowsPerPageOptions={[PAGE_SIZE]} // Having only one option removes the dropdown menu
        initialState={{
          sorting: {
            sortModel: [
              {
                field: defaultQueryState.sort!.sortField,
                sort: defaultQueryState.sort?.sortDirection,
              },
            ],
          },
          pagination: defaultQueryState.paginate,
        }}
        onSortModelChange={(model) => handleSortChange(model)}
        onFilterModelChange={(model) => handleFilterChange(model)}
        // This is used because it is triggered on click AND by next / previous buttons in DetailView
        onSelectionModelChange={(selectionModel: GridSelectionModel) =>
          // Because multiple selection is disabled this returns the selected row id
          notifyChange(selectionModel[0])
        }
        rowCount={totalItems}
        onPageChange={(number) =>
          dispatchQuery({
            type: QueryType.Paginate,
            values: { page: number, pageSize: PAGE_SIZE },
          })
        }
      />
    </Card>
  );
}
