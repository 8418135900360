import React from 'react';
import { Box, Button, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './FilterHeader.module.scss';
import TuneIcon from '@mui/icons-material/Tune';

interface FilterItem {
  name: string;
  value: string;
}

interface FilterHeaderProps {
  filters: FilterItem[];
  onResetFilters: () => void;
  onRemoveFilter: (filter: FilterItem) => void;
  onShowFilter: () => void;
}

const FilterHeader: React.FC<FilterHeaderProps> = ({ filters, onResetFilters, onRemoveFilter, onShowFilter }) => {
  const theme = useTheme();
  const isXsOrSm = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ mb: 1 }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyItems: 'center' }}>
        {isXsOrSm && (
          <Button
            variant='contained'
            onClick={onShowFilter}
            className={styles.filter_button}
            sx={{ borderRadius: '6px' }}
          >
            <TuneIcon sx={{ mr: 1 }} />
            Filter
          </Button>
        )}
        <Button
          variant='contained'
          onClick={onResetFilters}
          disabled={filters.length === 0}
          className={styles.filter_button}
          sx={{ borderRadius: '6px' }}
        >
          Alle Filter löschen
        </Button>
        {filters.map((filter, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid',
              borderRadius: '6px',
              padding: '2px 4px',
            }}
            className={styles.filter_button}
          >
            <Typography variant='body2' sx={{ ml: 1 }}>{`${filter.name}: ${filter.value}`}</Typography>
            <IconButton onClick={() => onRemoveFilter(filter)} size='small'>
              <CloseIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FilterHeader;
